import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import MailChimp from '../components/mailchimp';

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const categoryHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } in "${category}"`;

  return (
    <Layout>
      <SEO
        title={`Nieuwenhuis Web Development blog categories overview page`}
        desc={`Learn everything a web developer needs from our expert knowledge. From JavaScript, through HTML, WordPress, Angular or React, we have it all.`}
        pathname={`/blog/category/${category
          .toLowerCase()
          .replace(/\s/g, `-`)}`}
      />

      <section>
        <div className="py-6 text-center">
          <h1>{categoryHeader}</h1>
        </div>
      </section>
      <ul className="mt-6 m-auto max-w-screen-md">
        {edges.map(({ node }) => {
          const { slug } = node.fields;
          const { title, author, date, featuredImage } = node.frontmatter;

          return (
            <li className="pb-4 mb-2 border-b" key={slug}>
              <Link to={slug}>
                <div className="flex items-center justify-center md:justify-start">
                  <GatsbyImage
                    image={featuredImage?.childImageSharp.gatsbyImageData}
                    alt={title}
                    className="mr-6 md:mr-12"
                  />
                  <div className="w-3/4 md:w-1/2">
                    <p>{title}</p>
                    <p className="text-sm text-gray-600">
                      <span className="text-gray-900 leading-none">
                        {author}
                      </span>
                      <span className="mx-1">・</span>
                      <span>
                        <time
                          title={date}
                          dateTime={new Date(date).toISOString()}
                        >
                          {date}
                        </time>
                      </span>
                      <span className="mx-1">・</span>
                      <span>{node.timeToRead} min read</span>
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
      <MailChimp />
    </Layout>
  );
};

export default Categories;

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            author
            date(formatString: "D MMMM Y")
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 128, height: 128)
              }
            }
          }
        }
      }
    }
  }
`;
